import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['recipes', 'ingredients'];

  showIngredients(e) {
    e.preventDefault();

    this.recipesTarget.classList.add('d-none');
    this.ingredientsTarget.classList.remove('d-none');
  }

  showRecipes(e) {
    e.preventDefault();

    this.recipesTarget.classList.remove('d-none');
    this.ingredientsTarget.classList.add('d-none');
  }
}
